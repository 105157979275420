import { TrackingEvent } from '../../Entities/Schema.entity';

import { nlsSchema } from './NlsSchema';

type Props = {
  query: string;
  resultUrl?: string;
};

type NlsEvent = TrackingEvent<{
  query: string;
  resultUrl?: string;
}>;

export const nlsToEvent = ({ query, resultUrl }: Props): NlsEvent => {
  const event: NlsEvent = {
    schema: nlsSchema.ref,
    data: {
      query,
      resultUrl,
    },
  };

  return event;
};
