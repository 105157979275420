'use client';

import { useState } from 'react';

import { useAtom, useAtomValue } from 'jotai';
import { usePathname } from 'next/navigation';

import { Locale } from '@core/Entities/Locale/Locale.entity';
import { mappedLocales } from '@core/Utils/Intl/MappedLocales';
import { Dropdown, DropdownOptionProps } from '@gds/Dropdown/Dropdown';
import { France } from '@gds/Icons/Paths/France';
import { Germany } from '@gds/Icons/Paths/Germany';
import { UK } from '@gds/Icons/Paths/UK';
import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';

import { navTrayOpenAtom } from '../../Atoms/NavTrayOpenAtom';
import { DE_LOCALE } from '@core/Constants/Locale.constants';

const createLanguageOption = (id: string, label: string, value: string, icon: React.ReactNode) => ({
  id,
  label,
  value,
  leftContent: icon,
});

export const LanguageDropdown = ({
  locale,
  strings,
  trackingFunc,
  ...restProps
}: {
  locale: Locale;
  strings: Record<string, string>;
  className?: string;
  placeholderOption?: string;
  isMenuPositionedAbove?: boolean;
  trackingFunc?: (obj: Record<string, string>) => void;
}) => {
  const pathname = usePathname();
  const [isNavTrayOpen, setIsNavTrayOpen] = useAtom(navTrayOpenAtom);
  const { showDeFeatures: shouldShowDeLang } = useAtomValue(flagsAtom);

  const langConfig = {
    currentLang: mappedLocales[locale],
    options: [
      createLanguageOption('en-GB', strings.englishLabel, '/uk', <UK />),
      createLanguageOption('fr-FR', strings.frenchLabel, '/fr', <France />),
    ] as DropdownOptionProps[],
  };

  const shouldUseAutoTestUrl = locale === DE_LOCALE && pathname.includes('auto-tests');
  const shouldUseDefaultUrl = shouldShowDeLang && !shouldUseAutoTestUrl;

  if (shouldUseDefaultUrl) {
    langConfig.options.push(createLanguageOption('de-DE', strings.germanLabel, '/de', <Germany />));
  } else if (shouldUseAutoTestUrl) {
    langConfig.options.push(
      createLanguageOption('de-DE', strings.germanLabel, 'https://hey.car', <Germany />),
    );
  }

  const [selectedLang, setSelectedLang] = useState(
    langConfig.options.find(option => option.id === langConfig.currentLang),
  );

  const handleLanguageChange = (option: DropdownOptionProps | undefined) => {
    if (isNavTrayOpen) {
      setIsNavTrayOpen(false);
    }

    if (option) {
      if (pathname === option.value) return;
      if (trackingFunc) trackingFunc({ label: option.label, href: option.value as string });
      setSelectedLang(option);
      window.location.href = option.value as string;
    }
  };

  return (
    <Dropdown
      value={selectedLang}
      onChange={handleLanguageChange}
      options={langConfig.options}
      dataTestId="language-dropdown"
      showLeftLabelIcon
      {...restProps}
    />
  );
};
