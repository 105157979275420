import { Filter } from '../../../Entities/Search/SearchFilters/Filter.entity';

import { getModelNameFromId } from './GetModelNameFromId';
import { getVariantNameFromId } from './GetVariantNameFromId';

export const getSlugParamValueFromFilter = (filter: Filter) => {
  const valStr = Array.isArray(filter.value) ? filter.value[0] : filter.value;
  if (valStr && filter.id === 'model') return getModelNameFromId(valStr);
  if (valStr && filter.id === 'variant-type') return getVariantNameFromId(valStr);
  return valStr;
};
