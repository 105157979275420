import { Filter } from '../../Entities/Search/SearchFilters/Filter.entity';

const createQueryStrFromFilters = (paramsArray: Filter[]) => {
  //build query
  const queryString = paramsArray
    .map(({ id, value }) => {
      // Skip 'ev-wltp-range__gte' when it's "0"
      if (id === 'ev-wltp-range__gte' && value === '0') {
        return '';
      }

      return (
        value &&
        (Array.isArray(value)
          ? value.map(value => `${id}=${encodeURIComponent(value)}`).join('&') // if value is an array
          : `${id}=${encodeURIComponent(value!)}`)
      );
    })
    .filter(Boolean) // Remove empty strings
    .join('&');

  return queryString;
};

export { createQueryStrFromFilters };
