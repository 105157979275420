'use client';

import { PrefetchLink } from '@shared-components/PrefetchLink/PrefetchLink';
import { ctaClickedToEvent } from '@tracking/Schemas/CtaClicked/CtaClickedToEvent';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';

import { footerClickTracking } from './TrackedFooterLink.constants';
import { FooterTrackingObj, TrackedFooterLinkProps } from './TrackedFooterLink.entity';

export const trackEvent = (track?: {
  fn: ((trackingObj: FooterTrackingObj) => void) | undefined;
  obj: Partial<FooterTrackingObj>;
}) => {
  if (track && typeof track?.fn === 'function') {
    track.fn({ ...footerClickTracking, ...track.obj } as FooterTrackingObj);
  }
};

export const itemOnClick = (
  track?: {
    fn: ((trackingObj: FooterTrackingObj) => void) | undefined;
    obj: Partial<FooterTrackingObj>;
  },
  onClick?: () => void,
) => {
  trackEvent(track);
  if (typeof onClick === 'function') onClick();
};

export const trackingFunction = ({ category, label, href }: FooterTrackingObj) => {
  trackCustomEvent({
    event: ctaClickedToEvent({ ctaType: category, ctaWording: label, targetUrl: href || '' }),
    context: [],
  });
};

export const TrackedFooterLink = ({ trackingObj, children, ...rest }: TrackedFooterLinkProps) => {
  return (
    <PrefetchLink
      href={rest.href}
      onClick={e => {
        if (trackingObj?.label === 'Privacy settings') {
          e.preventDefault();
          if (window.__tcfapi) {
            /* eslint-disable @typescript-eslint/no-empty-function */
            window.__tcfapi('displayConsentUi', 2, function () {});
          } else {
            console.error('__tcfapi is not defined.');
          }
        }
        itemOnClick({
          fn: trackingFunction,
          obj: trackingObj,
        });
      }}
      {...rest}
    >
      {children}
    </PrefetchLink>
  );
};
