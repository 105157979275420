'use client';

import { useEffect, useRef } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import Button from '@gds/Button/Button';
import { Typography } from '@gds/Typography/Typography';

import { closeModalAtom, modalDataAtom } from '../Atoms/Modal.atom';

import { ModalCloseBehaviorProps } from '../Entities/Modal.entity';

import styles from './Modal.module.css';

export const ModalHeader = ({ customCloseIcon, enableHeaderClose }: ModalCloseBehaviorProps) => {
  const {
    header,
    onClose,
    headerClassName = '',
    headerVariant = 'h5',
  } = useAtomValue(modalDataAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const dialogRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dialogRef?.current?.focus();
  }, [dialogRef.current]);

  const close = () => {
    closeModal();
    onClose?.();
  };

  return (
    <div
      className={`${headerClassName} ${styles.header} ${
        onClose && enableHeaderClose ? styles.clickableHeader : ''
      }`}
      ref={dialogRef}
      onClick={onClose && enableHeaderClose ? close : undefined}
    >
      <Typography variant={headerVariant}>{header}</Typography>
      {onClose && (
        <Button
          className={styles.closeButton}
          data-test-id="close-button"
          variant="link"
          leftIcon={customCloseIcon}
          onClick={close}
          size="large"
        />
      )}
    </div>
  );
};
