'use client';

import { atom } from 'jotai';

import { atomWithStorage } from 'jotai/utils';
import Cookies from 'js-cookie';

import { createCookieStorage } from '../../Utils/CookieStorage';

export const favouritesAtom = atomWithStorage(
  `favourites-${Cookies.get('locale')}`,
  [],
  createCookieStorage<string[]>([]),
);

export const removeFromFavouritesAtom = atom(null, (_, set, heycarId) => {
  set(favouritesAtom, (currentFavourites: string[]) =>
    currentFavourites.filter((vehicleId: string) => vehicleId !== heycarId),
  );
});

export const addToFavouritesAtom = atom(null, (_, set, favData: { heycarId: string }) => {
  set(favouritesAtom, (currentFavourites: string[]) => [
    ...(currentFavourites || []),
    favData.heycarId,
  ]);
});
