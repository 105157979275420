'use client';

import { memo, useEffect, useRef } from 'react';

import { useAtomValue } from 'jotai';

import { ChevronLeft } from '@gds/Icons/Paths/ChevronLeft';
import { Close } from '@gds/Icons/Paths/Close';
import { Spinner } from '@gds/Icons/Paths/Spinner';
import { ThinSearch } from '@gds/Icons/Paths/ThinSearch';

import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';

import { useNaturalLanguageSearch } from './Hooks/UseNaturalLanguageSearch';

import styles from './NaturalLanguageSearch.module.css';

type Props = {
  validateUrl: (url: string) => boolean;
  strings: Record<string, string>;
  isPrimaryHeader?: boolean;
};

export const NaturalLanguageSearch = memo(({ validateUrl, strings, isPrimaryHeader }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const flags = useAtomValue(flagsAtom);
  const { query, setQuery, isVisible, setIsVisible, isLoading, handleSearch } =
    useNaturalLanguageSearch(validateUrl, strings);

  useEffect(() => {
    if (isVisible) inputRef.current?.focus();
  }, [isVisible]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData(formRef.current!);
    const searchQuery = formData.get('query') as string;
    handleSearch(searchQuery);
    inputRef.current?.blur();
  };

  return (
    <>
      <div
        className={styles.overlayBackground}
        data-is-primary-header={isPrimaryHeader}
        data-is-visible={isVisible}
        onClick={() => setIsVisible(false)}
      />
      <form
        className={styles.wrapper}
        onSubmit={onSubmit}
        ref={formRef}
        id="nls-form"
        data-is-primary-header={isPrimaryHeader}
        data-is-loading={isLoading}
        data-is-visible={isVisible}
        data-is-desktop-experiment-on={Boolean(flags?.naturalLanguageSearchDesktopExperiment)}
        aria-label={strings?.nlsLabel}
      >
        <div className={styles.formInnerWrapper} data-is-primary-header={isPrimaryHeader}>
          <ThinSearch
            className={styles.searchIcon}
            onClick={() => isPrimaryHeader && setIsVisible(false)}
          />
          <ChevronLeft className={styles.chevronLeft} onClick={() => setIsVisible(false)} />
          <Spinner className={styles.spinnerIcon} data-is-primary-header={isPrimaryHeader} />
          <input
            id="nls"
            name="query"
            type="search"
            ref={inputRef}
            placeholder={strings?.nlsPlaceholder}
            className={styles.input}
            defaultValue={query}
          />

          <Close
            className={styles.closeIcon}
            onClick={() => {
              inputRef.current!.value = '';
              setQuery('');
              inputRef.current?.focus();
            }}
            tabIndex={0}
          />
        </div>
      </form>
    </>
  );
});

NaturalLanguageSearch.displayName = 'NaturalLanguageSearch';
