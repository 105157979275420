'use client';

import { atom, useAtomValue } from 'jotai';

import { Toast, ToastProps } from '@gds/Toast/Toast';

import styles from './ToastManager.module.css';

type ToastWithId = ToastProps & { id: string };
export const toastListAtom = atom<ToastWithId[]>([]);

export const toastManagerAtom = atom<null, [ToastProps], undefined>(
  null,
  (get, set, toast: ToastProps) => {
    const toasts = get(toastListAtom);
    const id = Math.random().toString(36).substr(2, 9);
    const newToast: ToastWithId = { ...toast, id };

    set(toastListAtom, [...toasts, newToast] as any);

    setTimeout(() => {
      set(
        toastListAtom,
        toasts => toasts.map(t => (t.id === id ? { ...t, inactive: true } : t)) as any,
      );
    }, 5000);
  },
);

export const ToastManager = () => {
  const toasts = useAtomValue(toastListAtom);

  return (
    <div className={styles.toastList}>
      {toasts.map(toast => (
        <Toast key={toast.id} {...toast} />
      ))}
    </div>
  );
};
